import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import grievanceStyle from "ps-assets/jss/grievanceStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
//import benefitStyle from "ps-assets/jss/benefitstyle.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import PinDrop from "@material-ui/icons/PinDrop";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSnackbar from "components/Snackbar/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Helmet } from "react-helmet";
import CallComponent from "components/CallComponent";
//import RenderToLayer from "material-ui/internal/RenderToLayer";
var apiBaseUrl = process.env.API_URL;
class Grievance extends Component {
  constructor() {
    super();
    this.state = {
      flag: 2,
      name: [``, true],
      mobile: [``, true],
      landline: [``, false],
      address: [``, false],
      email: [``, true],
      policy_no: [``, true],
      subject: [``, true],
      claim_no: [``, true],
      grievance_details: [``, true],
      submitButton: false,
      ipdata: ``
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    let url = `${apiBaseUrl}form/grievance`;

    // this.setState({
    //   submitButton: true
    // })
    let name = this.state.name[0];
    let mobile = this.state.mobile[0];
    let landline = this.state.landline[0];
    let address = this.state.address[0];
    let email = this.state.email[0];
    let policy_no = this.state.policy_no[0];
    let subject = this.state.subject[0];
    let claim_no = this.state.claim_no[0];
    let grievance_details = this.state.grievance_details[0];

    this.setState({
      submitButton: true
    });

    // && this.state.name[1] === true ) || (mobile[0] === "" && this.state.mobile[1] === true ) || (email[0] === "" && this.state.email[1] === true ) || (landline[0] === "" && this.state.landline[1] === false ) || (address[0] === "" && this.state.address[1] === false ) || (policy_no[0] === "" && this.state.policy_no[1] === true ) || (subject[0] === "" && this.state.subject[1] === true ) || (claim_no[0] === "" && this.state.claim_no[1] === true ) || (grievance_details[0] === "" && this.state.grievance_details[1] === true )

    let data = {
      name: name != `` ? name : ``,
      mobile: mobile != `` ? mobile : ``,
      landline: landline != `` ? landline : ``,
      address: address != `` ? address : ``,
      email: email != `` ? email : ``,
      policy_no: policy_no != `` ? policy_no : ``,
      subject: subject != `` ? subject : ``,
      claim_no: claim_no != `` ? claim_no : ``,
      grievance_details: grievance_details != `` ? grievance_details : ``,
      userip: this.state.ipdata
    };
    let params = {
      method: `POST`,
      mode: `cors`,
      cache: `no-cache`,
      credentials: `same-origin`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    };

    if (
      name.length !== 0 &&
      this.state.name[1] === true &&
      (mobile.length !== 0 && this.state.mobile[1] === true) &&
      (email.length !== 0 && this.state.email[1] === true) &&
      (policy_no.length !== 0 && this.state.policy_no[1] === true) &&
      (subject.length !== 0 && this.state.subject[1] === true) &&
      (claim_no.length !== 0 && this.state.claim_no[1] === true) &&
      (grievance_details.length !== 0 &&
        this.state.grievance_details[1] === true)
    ) {
      let res = await fetch(url, params);
      if (res.status !== 200) {
        console.log(`error`);
        // ErrorStatus.responseStatus = true;
      } else {
        this.setState({
          name: [``, true],
          mobile: [``, true],
          landline: [``, false],
          address: [``, false],
          email: [``, true],
          policy_no: [``, true],
          subject: [``, true],
          claim_no: [``, true],
          grievance_details: [``, true],
          flag: 1,
          submitButton: false
        });
      }
    }
  };
  setFlag = f => {
    this.setState({
      flag: f
    });
  };
  handleChange = e => {
    if (
      e.target.id === `name` ||
      e.target.id === `mobile` ||
      e.target.id === `email` ||
      e.target.id === `policy_no` ||
      e.target.id === `subject` ||
      e.target.id === `claim_no` ||
      e.target.id === `grievance_details`
    ) {
      if (e.target.id === `mobile`) {
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: [e.target.value, true] });
        }
      } else if (e.target.id === `grievance_details`) {
        if (e.target.value.length <= 300) {
          this.setState({ [e.target.id]: [e.target.value, true] });
        }
      } else if (e.target.id === `name`) {
        if ((`nice`, e.target.value.search(`^[a-zA-Z. ']*$`) === 0)) {
          this.setState({ [e.target.id]: [e.target.value, true] });
        }
      } else {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else {
      this.setState({ [e.target.id]: [e.target.value, false] });
    }
  };
  componentDidMount = async () => {
    if (this.state.ipdata === ``) {
      let response = await fetch(`https://api.ipify.org/`);
      let NetworkIp = await response.text();
      this.setState({
        ipdata: NetworkIp
      });
    }
  };

  render() {
    var phoneno = new RegExp(
      /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
    );
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    const { classes, data } = this.props;
    var Images = data.allStrapiImages.edges;
    var clrImage = Images[1];
    var grievance_data = data.allStrapiEntities.edges;
    var grievance_total;

    for (let i in grievance_data) {
      var document = grievance_data[parseInt(i)];

      var descArr = document.node.description.split(`\n`);

      var desc = descArr.map((line, index) => {
        return (
          <div key={index}>
            <h5 className={classes.description}>{line}</h5>
          </div>
        );
      });

      if (document.node.title == `Grievance Redressal`) {
        grievance_total = (
          <div>
            {/* <h2 className={`${classes.title}`}>{document.node.title}</h2> */}
            <h5
              className={classes.description}
              style={{ textAlign: `left`, paddingTop: `20px` }}
            >
              {desc}
            </h5>
          </div>
        );
      }
    }

    return (
      <Layout
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
          <title>Grievance Redressal | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href="https://www.starhealth.in/grievance-redressal"
            />
            <meta name="title" content="Grievance Redressal | StarHealth.in" />
            <meta name="twitter:title" content="Grievance Redressal | StarHealth.in" />
            <meta
              name="description"
              content="Star Health Insurance takes special care in redressing the complaints in a more humane and sophisticated manner, we are always on customers side."
            />
            <meta
              name="keywords"
              content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
            />
            <meta property="og:title" content="Grievance Redressal | StarHealth.in" />
            <meta property="og:description" content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India" />
            <meta property="og:url" content="https://www.starhealth.in/grievance-redressal"/>
            <meta name="Grievance Redressal | StarHealth.in" />
            <meta name="twitter:description" content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India" />
            <meta property="twitter:url" content="https://www.starhealth.in/grievance-redressal" />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100 }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer className={classes.container}>
                <GridItem
                  md={8}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  style={{ paddingBottom: 20 }}
                >
                  {grievance_total}
                </GridItem>

                <GridItem
                  md={8}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <div
                    className={`${classes.description}`}
                    style={{ color: `#999` }}
                  >
                    <Accordion
                      active={0}
                      activeColor="primary"
                      collapses={[
                        {
                          title: <span style={{ fontSize: 20 }}>Level 1</span>,
                          content: (
                            <div>
                              <h5
                                className={`${classes.description} ${classes.textCenter}`}
                              >
                                {` `}
                                <a
                                  href="/sites/default/files/grievance-level-1-version-2.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={` ${classes.textBold}`}
                                  style={{ color: `#35488a` }}
                                >
                                  Click Here{` `}
                                </a>
                                to view the contact details of the Grievance
                                officer of the policy servicing office.
                              </h5>
                              <h5
                                className={`${classes.description} ${classes.textCenter}`}
                              >
                                {` `}
                                <a
                                  href="/sites/default/files/zone-wise-grievances-coordinators.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`  ${classes.textBold}`}
                                  style={{ color: `#35488a` }}
                                >
                                  Click Here{` `}
                                </a>
                                {` `}
                                to view the contact details of the Grievance
                                officer (Coordinator) of your ZO/AO.
                              </h5>
                              <h5
                                className={`${classes.description} ${classes.textCenter}`}
                              >
                                {` `}
                                <PinDrop className={`${classes.imageIcon}`} />
                                {` `}
                                <Link
                                  to="/locate-us"
                                  className={`  ${classes.textBold}`}
                                  style={{ color: `#35488a` }}
                                >
                                  {` `}
                                  Office Locator{` `}
                                </Link>
                                {` `}
                              </h5>
                            </div>
                          )
                        },
                        {
                          title: <span style={{ fontSize: 20 }}>Level 2</span>,
                          content: (
                            <div
                              className={`${classes.description} ${classes.textLeft}  `}
                            >
                              <h5>
                                If you are not satisfied with our Zonal
                                Grievance Coordinator&apos;s response then Feel
                                free to call us at our Sales & Services <CallComponent contactNumber="044 6900 6900" /> .{` `}
                              </h5>
                              <h5
                                className={`${classes.description} ${classes.textCenter}  `}
                              >
                                OR
                              </h5>
                              <h5>
                                Provide the details here below to register your
                                grievance online.
                              </h5>
                              <h5 className={`${classes.title}  `}>
                                Write to us
                              </h5>
                              <form onSubmit={this.handleSubmit}>
                                <GridContainer>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Name *"
                                      id="name"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.name[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.name[1] &&
                                        this.state.name[0].length === 0
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Mobile *"
                                      id="mobile"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.mobile[0]}
                                      error={
                                        (this.state.submitButton &&
                                          this.state.mobile[1] &&
                                          !this.state.mobile[0]) ||
                                        (this.state.mobile[0].length != 0 &&
                                          this.state.mobile[0].search(
                                            phoneno
                                          ) !== 0)
                                      }
                                    />
                                    {this.state.mobile[0].length != 0 &&
                                      this.state.mobile[0].search(phoneno) !==
                                        0 && (
                                      <FormHelperText
                                        id="my-helper-text"
                                        error
                                      >
                                          Mobile Number must be 10 digits{` `}
                                      </FormHelperText>
                                    )}
                                  </GridItem>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Landline"
                                      id="landline"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.landline[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.landline[1] &&
                                        !this.state.landline[0]
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={6} sm={4}>
                                    <CustomInput
                                      labelText="Address"
                                      id="address"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        multiline: true,
                                        rows: 6,
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.address[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.address[1] &&
                                        !this.state.address[0]
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={6} sm={4}>
                                    <CustomInput
                                      labelText="Email *"
                                      id="email"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.email[0]}
                                      error={
                                        (this.state.submitButton &&
                                          this.state.email[1] &&
                                          !this.state.email[0]) ||
                                        (this.state.email[0].length !== 0 &&
                                          this.state.email[0].search(
                                            emailRegex
                                          ) !== 0)
                                      }
                                    />
                                    {this.state.email[0].length != 0 &&
                                      this.state.email[0].search(emailRegex) !==
                                        0 && (
                                      <FormHelperText
                                        id="my-helper-text"
                                        error
                                      >
                                          Email Id is invalid{` `}
                                      </FormHelperText>
                                    )}
                                  </GridItem>
                                </GridContainer>

                                <h5 className={`${classes.textBold}  `}>
                                  Other Information
                                </h5>
                                <GridContainer>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Policy No *"
                                      id="policy_no"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.policy_no[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.policy_no[1] &&
                                        !this.state.policy_no[0]
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Subject *"
                                      id="subject"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.subject[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.subject[1] &&
                                        !this.state.subject[0]
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={4} sm={4}>
                                    <CustomInput
                                      labelText="Claim Number *"
                                      id="claim_no"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: false
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.claim_no[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.claim_no[1] &&
                                        !this.state.claim_no[0]
                                      }
                                    />
                                  </GridItem>
                                  <GridItem md={12} sm={12}>
                                    <CustomInput
                                      labelText="Grievance details(Below 300 characters) *"
                                      id="grievance_details"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        multiline: true,
                                        rows: 6,
                                        required: false,
                                        style: {
                                          lineHeight: `2.5`
                                        }
                                      }}
                                      onChange={this.handleChange}
                                      value={this.state.grievance_details[0]}
                                      error={
                                        this.state.submitButton &&
                                        this.state.grievance_details[1] &&
                                        !this.state.grievance_details[0]
                                      }
                                    />
                                  </GridItem>
                                </GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ paddingTop: 27, textAlign: `left` }}
                                >
                                  <span style={{ fontSize: 14 }}>
                                    * Fields are Mandatory
                                  </span>
                                  <div
                                    className={classes.textCenter}
                                    style={{ textAlign: `center` }}
                                  >
                                    <Button
                                      color="primary"
                                      round
                                      type={`submit`}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </GridItem>
                                {this.state.flag == 1 && (
                                  <CustomSnackbar
                                    open={true}
                                    duration={6000}
                                    status={`success`}
                                    message={`Successfully submitted`}
                                    flag={this.setFlag}
                                    title={this.title}
                                  />
                                )}
                                {this.state.flag == 0 && (
                                  <CustomSnackbar
                                    open={true}
                                    duration={6000}
                                    status={`warning`}
                                    message={`Please enter any field`}
                                    flag={this.setFlag}
                                    title={`Grievance`}
                                  />
                                )}
                              </form>
                            </div>
                          )
                        },
                        {
                          title: <span style={{ fontSize: 20 }}>Level 3</span>,
                          content: (
                            <div
                              className={`${classes.description} ${classes.textLeft}`}
                            >
                              <h5
                                className={`${classes.title} ${classes.textLeft}`}
                              >
                                Contact
                              </h5>
                              <span style={{ fontSize: 17 }}>
                                Mrs. Radha Vijayaraghavan,
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                                Grievance Redressal Officer,
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                                Corporate Grievance Department,
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              4th Floor, Balaji Complex, No. 15, Whites Lane,
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              Whites Road, Royapettah, Chennai- 600014
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              (Land mark: In the lane next to Satyam Theatre Parking Area)
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              Direct : 044-4366 4600
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              Senior Citizens - 044- 6900 7500
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                              Mail ID:- gro@starhealth.in.
                              </span>
                              <br />
                              <span style={{ fontSize: 17 }}>
                                <a
                                  href="mailto:Grievances@starhealth.in"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={` ${classes.textBold}`}
                                  style={{ color: `#35488a` }}
                                >
                                  grievances@starhealth.in
                                </a>
                              </span>
                            </div>
                          )
                        },
                        {
                          title: <span style={{ fontSize: 20 }}>Level 4</span>,
                          content: (
                            <div
                              className={`${classes.description} ${classes.textLeft}`}
                            >
                              <h5>
                              If all the above steps do not meet your expectations or your issue is still unresolved or you are unhappy with our decision, then you can escalate to Insurance Regulatory Authority by registering your complaint with
                              </h5>
                              <ul>
                                <h5>
                                  <li>
                                  IRDAI Grievance Call Centre (IGCC) Toll Free No. 155255 or 1800 4254 732.{` `}
                                  </li>
                                </h5>
                                <h5>
                                  <li>
                                  Registering your complaint in the Integrated Grievance Management System (IGMS ) Portal {` `}
                                  <a href="https://bimabharosa.irdai.gov.in/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={` ${classes.textBold}`}
                                  style={{ color: `#35488a`, textDecoration: `underline` }}>
                                    https://bimabharosa.irdai.gov.in/
                                    </a>{` `}
                                  </li>
                                </h5>
                                <h5>
                                  <li>Sending your complaint through Email to {` `}
                                    <a
                                      href="mailto:complaints@irda.gov.in"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={` ${classes.textBold}`}
                                      style={{ color: `#35488a`}}
                                    >
                                      complaints@irdai.gov.in
                                    </a>
                                  </li>
                                </h5>
                              </ul>
                              <h5>OR</h5>
                              <h5>
                               The complaint may be lodged with the office of Insurance Ombudsman. Please click for Contact details of Insurance Ombudsmen here {` `}
                                <a
                                  href="https://www.cioins.co.in/Ombudsman"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={` ${classes.textBold}`}
                                  style={{ color: `#35488a`,textDecoration: `underline` }}
                                >
                                  https://www.cioins.co.in/Ombudsman{` `}
                                </a>
                              </h5>
                            </div>
                          )
                        }
                      ]}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
Grievance.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};
export default withStyles(grievanceStyle)(Grievance);
export const grievance = graphql`
  query grievance {
    allStrapiEntities(filter: { category: { eq: "grievance_redressal" } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "grievance_redressal" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
